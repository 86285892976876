import React, { useLayoutEffect } from 'react'
import { Row, Col, Card } from 'antd'
// import AdSense from 'react-adsense'
// import data from './data.json'

const FeaturedProducts = () => {
  useLayoutEffect(() => {
    ;(window.adsbygoogle = window.adsbygoogle || []).push({})
  }, [])

  return (
    <Row className="mb-2" type="flex">
      <Col xs={24}>
        <Card className="featured-product h-full" bodyStyle={{ padding: 15 }}>
          <Row align="middle" justify="center">
            <Col
              xs={24}
              style={{
                display: 'flex',
                justifyContent: 'center',
                minWidth: 400,
                height: 90
              }}
            >
              <ins
                className="adsbygoogle"
                style={{
                  display: 'inline-block',
                  minWidth: 400,
                  maxWidth: 970,
                  width: '100%',
                  height: 90
                }}
                data-ad-client="ca-pub-0125377573692321"
                data-ad-slot="4087327082"
                // data-full-width-responsive="true"
              ></ins>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  )
}

export default FeaturedProducts
